import React, { useState } from "react";
import ProductList from "../components/ProductList";
import "../styles/Shop.css";

const Shop = () => {
  const [filter, setFilter] = useState("");

  return (
    <section className="shop">
      <h1>Shop Our Products</h1>
      <input
        type="text"
        placeholder="Search products..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <ProductList filter={filter} />
    </section>
  );
};

export default Shop;
