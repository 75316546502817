import React from "react";

const Information = () => {
  return (
    <section className="information">
      <h1>Information</h1>
      <p>Here you can give details about your store, return policies, and more.</p>
    </section>
  );
};

export default Information;
