import React from 'react';
import '../styles/Logo.css';
import logoImage from '../assets/Star.Ryhwa.PNG'; // Import your logo image

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={logoImage} alt="Star Ryhwa Logo" className="logo-image" />
    </div>
  );
};

export default Logo;
