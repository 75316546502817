import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import Logo from "../components/Logo.js"; // Import the Logo component
import ProductSection from "../components/ProductSection.js"; // Import ProductSection

const Home = () => {
  return (
    <section className="home">
      <Logo /> {/* Add the Logo component here */}
      <h1>Welcome to My E-Commerce</h1>
      <p>Find the best products just for you!</p>
      <Link to="/shop" className="button">Shop Now</Link>
      
      {/* Add Product Section here */}
      <ProductSection />
    </section>
  );
};

export default Home;
