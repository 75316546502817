
const products = [
    { 
    id: 1,
    name: "Prints", 
    price: "$1", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 2,
    name: "Stickers", 
    price: "$4", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 3,
    name: "Collages", 
    price: "$10", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 4,
    name: "Toploaders (All)", 
    price: "$8", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 5,
    name: "Unofficial Photocards", 
    price: "$2", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 6,
    name: "Large Bags", 
    price: "$20", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 7,
    name: "Small Bags", 
    price: "$15", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 8,
    name: "Official Photocards", 
    price: "$10", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 9,
    name: "Sanitizer", 
    price: "$8", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 10,
    name: "Mystery Bag", 
    price: "$15", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 11,
    name: "BTS Air Diffuser", 
    price: "$40", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 12,
    name: "Pop Socket", 
    price: "$5", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 13,
    name: "BTS Mugs", 
    price: "$20", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 14,
    name: "Keychains (All)", 
    price: "$6", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 15,
    name: "Card Holders (All)", 
    price: "$10", 
    image: "https://via.placeholder.com/150" 
    },
    { 
    id: 16,
    name: "Pins", 
    /*
    subcategories: [
        { 
        id: 17,
        name: "BTS Ice Cream", 
        price: "$10", 
        image: "https://via.placeholder.com/150" 
        },
        { 
        id: 18,
        name: "BTS Disco", 
        price: "$10", 
        image: "https://via.placeholder.com/150" 
        },
        { 
        id: 19,
        name: "Black Swan", 
        price: "$10", 
        image: "https://via.placeholder.com/150" 
        },
        { 
        id: 20,
        name: "Rest of Pins", 
        price: "$7", 
        image: "https://via.placeholder.com/150" 
        }
    ]
        */
    }
];


export default products;

