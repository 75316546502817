import React from "react";
import Slider from "react-slick";
import "../styles/ProductSection.css"; // Import the CSS file
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import products from "./Products";

const ProductSection = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <section className="product-section">
      <h2 className="featured-header">Featured Products</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.price}</p>
            <button className="button">View Product</button>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ProductSection;
