import React from "react";

const ProductItem = ({ product }) => {
  return (
    <div className="product-item">
      <h3>{product.name}</h3>
      <p>{product.price}</p>
      <button className="button">Add to Cart</button>
    </div>
  );
};

export default ProductItem;
